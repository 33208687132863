<template>
  <b-card
    no-body
    class="product-list"
  >
    <!-- <div>
      <CustomTable
        :add_product_category_button="cate"
        :items="items"
        :fields="fields"
        custom-link="product/edit"
        @handlerSort="handlerSort"
        @handlerDelete="handlerDelete"
      />
      <PopupAddProduct v-model="model" />
    </div> -->
    <!-- <div v-else>
      <no-data />
    </div> -->

    <b-card-header>
      <div
        class="d-flex flex-wrap justify-content-between align-items-center mb-2 w-100"
        style="gap: 8px;"
      >
        <div>
          <h4 class="mb-0">
            {{ $t('Product Management') }}
          </h4>
        </div>
        <b-button
          variant="primary"
          type="button"
          class="ml-2"
          to="/product/add"
        >
          <feather-icon
            icon="PlusIcon"
            class="mr-50"
          />
          {{ $t('Create new') }}
        </b-button>
        <b-button
          variant="primary"
          type="button"
          class="ml-2"
          to="/product/add"
        >
          <feather-icon
            icon="PlusIcon"
            class="mr-50"
          />
          {{ $t('Create new') }}
        </b-button>
      </div>

      <div class="d-flex align-items-center justify-content-between w-100 bd-b-1">
        <div class="filter-tab">
          <div
            v-for="item in filterTabs"
            :key="item.id"
            :class="['filter-tab__item', {'active': filterSelected == item.id}]"
            @click="filterSelected = item.id"
          >
            {{ $t(item.label) }}
            <!-- ({{ productCounter(item.id) }}) -->
          </div>
        </div>
        <div class="filter-advance__item filter-advance__search">
          <b-form-group>
            <b-form-input
              v-model="modelFilter.search"
              :placeholder="$t('Search by product name, SKU')"
              @keyup.enter="handleFilter"
            />
          </b-form-group>
          <b-form-group>
            <v-select
              v-model="modelFilter.productCategorySelected"
              label="name"
              class="v-select-custom"
              :options="productCategories"
              :clearable="false"
              :placeholder="$t('Danh mục sản phẩm')"
              :reduce="(option) => option.id"
            />
          </b-form-group>
          <b-form-group>
            <v-select
              v-model="modelFilter.e_commerce"
              label="name"
              class="v-select-custom"
              :options="salesChannels"
              :clearable="false"
              :placeholder="$t('Kênh bán hàng')"
              :reduce="(option) => option.id"
            />
          </b-form-group>
          <!-- <b-form-group
            class="actions"
          >
            <b-button
              variant="outline-primary"
              @click="filterReset()"
            >{{ $t('Reset') }}</b-button>
            <b-button
              variant="outline-success"
              @click="handleFilter()"
            >{{ $t('Apply') }}</b-button>
          </b-form-group> -->
        </div>
        <sync-shopee v-model="productSelected" />
        <sync-lazada v-model="productSelected" />
      </div>

      <div
        v-if="activeFilter"
        class="d-flex align-items-center"
        :class="{'my-1': modelFilter.search || modelFilter.e_commerce}"
        style="gap: 8px;"
      >
        <b-badge
          v-if="modelFilter.search"
          variant="light-dark cursor-pointer"
          @click="modelFilter.search = null"
        >
          {{ modelFilter.search }}
          <span class="text-danger">X</span>
        </b-badge>
        <b-badge
          v-if="modelFilter.productCategorySelected"
          variant="light-dark cursor-pointer"
          @click="modelFilter.productCategorySelected = null"
        >
          {{ productCategories.find(obj => obj.id === modelFilter.productCategorySelected).name }}
          <span class="text-danger">X</span>
        </b-badge>
        <b-badge
          v-if="modelFilter.e_commerce !== null"
          variant="light-dark cursor-pointer"
          @click="modelFilter.e_commerce = null"
        >
          {{ salesChannels.find(obj => obj.id === modelFilter.e_commerce).name }}
          <span class="text-danger">X</span>
        </b-badge>
      </div>

      <!-- <div class="filter-advance w-100 mt-2">
        <div class="filter-advance__item filter-advance__search">
          <b-form-group>
            <b-form-input
              v-model="modelFilter.search"
              :placeholder="$t('Search by product name, SKU')"
            />
          </b-form-group>
          <b-form-group>
            <v-select
              v-model="modelFilter.productCategorySelected"
              label="name"
              class="v-select-custom"
              :options="productCategories"
              :clearable="false"
              :placeholder="$t('Select product category')"
              :reduce="(option) => option.id"
            />
          </b-form-group>
          <b-form-group>
            <v-select
              v-model="modelFilter.e_commerce"
              label="name"
              class="v-select-custom"
              :options="salesChannels"
              :clearable="false"
              :placeholder="$t('Select sales channel')"
              :reduce="(option) => option.id"
            />
          </b-form-group>
          <b-form-group
            class="actions"
          >
            <b-button
              variant="outline-primary"
              @click="filterReset()"
            >{{ $t('Reset') }}</b-button>
            <b-button
              variant="outline-success"
              @click="handleFilter()"
            >{{ $t('Apply') }}</b-button>
          </b-form-group>
        </div>
        <div
          class="filter-advance__item d-flex flex-wrap align-items-center justify-content-between"
          style="gap: 8px;"
        >
          <div>
            <b-dropdown
              v-if="productSelected.length > 0"
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              :text="`${$t('Selected')} ${productSelected.length} ${$t('to sync up channels')}`"
              variant="secondary"
            >
              <b-dropdown-item @click="showPopupShopee" v-b-modal.modal-popup-sync-product-shopee>
                {{$t('Sync to')}} Shopee
              </b-dropdown-item>
              <b-dropdown-item @click="showPopupLazada" v-b-modal.modal-popup-sync-product-lazada>
                {{$t('Sync to')}} Lazada
              </b-dropdown-item>
              <b-dropdown-item @click="showPopupTiktok">
                {{$t('Sync to')}} Tiktok
              </b-dropdown-item>
            </b-dropdown>
          </div>
          <sync-shopee v-model="productSelected" />
          <sync-lazada v-model="productSelected" />
          <b-button-group>
            <b-button
              variant="outline-success"
              :class="{'disabled': productSelected.length === 0}"
            >
              {{ $t('Activated') }}
            </b-button>
            <b-button
              variant="outline-dark"
              :class="{'disabled': productSelected.length === 0}"
            >
              {{ $t('Deactivate') }}
            </b-button>
          </b-button-group>
        </div>
      </div> -->
    </b-card-header>

    <b-card-body>
      <vue-good-table
        class="custom-good-table"
        style-class="vgt-table"
        :columns="fields"
        :rows="filterItems"
        :pagination-options="{
          enabled: true,
          perPage:pageLength
        }"
        :select-options="{
          enabled: true,
          disableSelectInfo: false,
          selectionInfoClass: 'custom-class',
          selectionText: 'sản phẩm đã được chọn trên trang này',
          clearSelectionText: 'Huỷ',
          selectOnCheckboxOnly: true
        }"
        @on-selected-rows-change="selectionChanged"
      >
        <div slot="selected-row-actions">
          <b-dropdown
            v-if="productSelected.length > 0"
            text="Chọn thao tác"
            variant="outline-primary"
          >
            <b-dropdown-group
              id="dropdown-group-1"
              header="Đồng bộ đơn hàng"
            >
              <b-dropdown-item
                v-b-modal.modal-popup-sync-product-shopee
                @click="showPopupShopee"
              >
                {{ $t('Sync to') }} Shopee
              </b-dropdown-item>
              <b-dropdown-item
                v-b-modal.modal-popup-sync-product-lazada
                @click="showPopupLazada"
              >
                {{ $t('Sync to') }} Lazada
              </b-dropdown-item>
              <b-dropdown-item @click="showPopupTiktok">
                {{ $t('Sync to') }} Tiktok
              </b-dropdown-item>
            </b-dropdown-group>
            <b-dropdown-group
              id="dropdown-group-2"
              header="Thao tác"
            >
              <b-dropdown-item>
                Ẩn
              </b-dropdown-item>
              <b-dropdown-item>
                Hiện
              </b-dropdown-item>
            </b-dropdown-group>
          </b-dropdown>
        </div>
        <!-- Table: Head -->
        <template
          slot="table-column"
          slot-scope="props"
        >
          <span>
            {{ $t(props.column.label) }}
          </span>
        </template>

        <!-- Table: Rows -->
        <template
          slot="table-row"
          slot-scope="props"
        >
          <!-- Column: Products -->
          <span v-if="props.column.field === 'name'">
            <div class="d-flex">
              <b-avatar
                :src="props.row.avatar"
                square
                variant="light-primary"
                size="lg"
              />
              <div
                class="d-flex flex-column pl-1"
                style="max-width: 300px;"
              >
                <router-link
                  v-b-tooltip.hover.top="props.row.name"
                  class="line-clamp"
                  :to="`edit/${props.row.id}?lang=${currentLang}&product_id=${props.row.product_id}`"
                  :title="props.row.name"
                >{{ props.row.name }}</router-link>
                <strong>{{ props.row.sku }}</strong>
                <strong>{{ props.row.totalAttribute }} {{ $t('variation') }}</strong>
              </div>
            </div>
          </span>

          <!-- Column: Price -->
          <span
            v-else-if="props.column.field === 'price'"
            class="d-block text-center"
          >
            {{ (props.row.price) }}
          </span>

          <!-- Column: Status -->
          <span
            v-else-if="props.column.field === 'is_active'"
            class="d-block text-center"
          >
            <b-badge
              :variant="props.row.is_active ? 'light-success' : 'light-dark'"
              style="max-width: 140px;"
            >
              {{ $t( props.row.is_active ? 'product_status_active' : 'product_status_disable') }}
            </b-badge>
          </span>

          <!-- Column: Channel -->
          <span
            v-else-if="props.column.field === 'channel'"
            class="d-block text-center"
          >
            <div>
              <!-- <img v-if="props.row.shopee_id === 0" width="18" height="18" src="/website.png" alt="Web"> -->
              <img
                v-if="props.row.shopee_id"
                class="ml-1"
                width="18"
                height="18"
                src="/logo_shopee.png"
                alt="Shopee"
              >
              <img
                v-if="props.row.lazada_id"
                class="ml-1"
                width="18"
                height="18"
                src="/lazada.png"
                alt="Lazada"
              >
              <img
                v-if="props.row.tiktok_id"
                class="ml-1"
                width="18"
                height="18"
                src="/tiktok.png"
                alt="Tiktok"
              >
            </div>
          </span>

          <!-- Column: Actions -->
          <span
            v-else-if="props.column.field === 'actions'"
            class="d-block text-center"
          >
            <b-button
              variant="flat-dark"
              class="btn-icon rounded-circle"
              :to="`edit/${props.row.id}?lang=${currentLang}&product_id=${props.row.product_id}`"
            >
              <feather-icon icon="Edit3Icon" />
            </b-button>
            <b-button
              variant="flat-danger"
              class="text-danger btn-icon rounded-circle"
              @click="handlerDelete(props.row.id)"
            >
              <feather-icon icon="TrashIcon" />
            </b-button>
          </span>

          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- pagination -->
        <template
          slot="pagination-bottom"
          slot-scope="props"
        >
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap ">
                Showing 1 to
              </span>
              <b-form-select
                v-model="pageLength"
                :options="['10','20', '30', '50', '100']"
                class="mx-1"
                @input="(value)=>props.perPageChanged({currentPerPage:value})"
              />
              <span class="text-nowrap"> of {{ totalPage }} entries </span>
            </div>
            <div>
              <b-pagination
                v-model="currentPageOriginal"
                :total-rows="totalPage"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value)=>props.pageChanged({currentPage:value})"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </b-card-body>

    <!-- popup -->
    <b-modal
      id="modal-popup-sync-product"
      ok-title="Save"
      scrollable
      size="lg"
      title="Đồng bộ sản phẩm lên Tiktok Shop"
      no-close-on-backdrop
      hide-footer
    >
      <div class="container-ctrl">
        <div>
          <ul v-if="sycnning">
            <li>
              Người dùng cần chọn Kho cho các sản phẩm được đăng lên Tiktok Shop
            </li>
            <li>
              Người dùng cần chọn danh mục theo danh mục Tiktok Shop
            </li>
            <li>
              Trường Thuộc tính sản phẩm bắt buộc phải được chọn theo thứ tự biếm thể sản phẩm ở website
            </li>
          </ul>
          <span v-else>Đồng bộ thành công <strong class="text-success">{{ countDone }}/{{ productSelected.length }} sản phẩm</strong>, <span class="text-danger">{{ countError }} đồng bộ lỗi</span></span>
          <!-- <p>Please choose the right category for your product.</p> -->
          <hr>
          <b-form-group
            :label="`*`+$t('Warehouse')+`:`"
            label-cols-md="12"
            class="align-items-center"
          >
            <v-select
              v-model="modelSyncTiktok.warehouse_id"
              label="warehouse_name"
              class="v-select-custom"
              :options="warehouseTiktok"
              :clearable="false"
              :placeholder="$t('Select warehouse')"
              :reduce="(option) => option.warehouse_id"
            />
          </b-form-group>
          <b-form-group
            :label="`*`+$t('Category')+`:`"
            label-cols-md="12"
            class="align-items-center"
          >
            <b-input-group
              v-b-modal.modal-popup-category-tiktok
              class="input-group-merge"
            >
              <b-input-group-prepend is-text>
                <feather-icon icon="Edit2Icon" />
              </b-input-group-prepend>
              <b-form-input
                v-model="modelSyncTiktok.category_name"
                readonly
                :placeholder="$t('Select product category')"
              />
            </b-input-group>
            <!-- <small v-if="categoryIdErrorText !== null" class="text-danger">{{
              categoryIdErrorText
            }}</small> -->
            <popup-category-tiktok v-model="modelSyncTiktok" />
          </b-form-group>
          <b-form-group
            v-if="attributeTiktok && attributeTiktok.length > 0"
            :label="`*`+$t('Attribute')+`:`"
            label-cols-md="12"
            class="align-items-center"
          >
            <v-select
              v-model="modelSyncTiktok.attribute_id"
              label="name"
              class="v-select-custom"
              multiple
              :options="attributeTiktok"
              :clearable="false"
              :placeholder="$t('Select attribute')"
              :reduce="(option) => option.id"
            />
          </b-form-group>
        </div>
        <div>
          <div class="d-flex justify-content-between p-1 bg-gray">
            <div>Sản Phẩm</div>
            <div>Trạng thái</div>
          </div>
          <div
            v-for="(item, index) in productSelected"
            :key="index"
            class="d-flex justify-content-between p-1"
          >
            <div class="d-flex align-items-center">
              <b-avatar
                square
                variant="light-dark"
                :src="item.avatar"
              />
              <span class="pl-1">{{ item.name }}</span>
            </div>
            <div class="pl-2">
              <feather-icon
                v-if="item.loading == 0"
                icon="MinusIcon"
                size="24"
                class="text-primary"
              />
              <b-spinner
                v-else-if="item.loading == 1"
                label="Loading..."
              />
              <feather-icon
                v-else-if="item.loading == 2"
                icon="CheckIcon"
                size="24"
                class="text-success"
              />
              <feather-icon
                v-else
                v-b-tooltip.hover.top="item.error_text"
                icon="AlertCircleIcon"
                size="24"
                class="text-warning"
              />
            </div>
          </div>
        </div>
        <hr>
        <div class="text-right mt-2">
          <b-button
            variant="outline-primary"
            type="button"
            size="sm"
            @click="closeModalTiktok"
          >
            {{ $t('Cancel') }}
          </b-button>
          <b-button
            variant="primary"
            type="button"
            class="ml-2"
            size="sm"
            @click="handleSyncToTiktok"
          >
            <feather-icon
              icon="RefreshCwIcon"
              class="mr-50"
            />
            {{ $t('Sync product') }}
          </b-button>
        </div>
      </div>
    </b-modal>
  </b-card>
</template>
<script>
import {
  BButton,
  // BButtonGroup,
  BAvatar,
  BCard,
  BCardHeader,
  BCardBody,
  VBTooltip,
  BFormInput,
  BFormGroup,
  BFormSelect,
  BPagination,
  BBadge,
  BDropdown,
  BDropdownItem,
  BDropdownGroup,
  BInputGroup,
  BInputGroupPrepend,
  BSpinner,
  // BProgress,
  // BCollapse,
  // BRow,
  // BCol,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { VueGoodTable } from 'vue-good-table'
import Request from '@/plugins/service/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import general from '@/mixins/index'
import VSelect from 'vue-select'
import PopupCategoryTiktok from './componentCategoryChannels/PopupTiktok.vue'
import SyncShopee from './syncto-channels/Shopee.vue'
import SyncLazada from './syncto-channels/Lazada.vue'

export default {
  components: {
    // PopupAddProduct,
    VueGoodTable,
    BButton,
    // BButtonGroup,
    BAvatar,
    BCard,
    BCardHeader,
    BCardBody,
    BFormInput,
    BFormGroup,
    BFormSelect,
    BPagination,
    BBadge,
    BDropdown,
    BDropdownItem,
    BDropdownGroup,
    BInputGroup,
    BInputGroupPrepend,
    BSpinner,
    // BProgress,
    // BCollapse,
    // BRow,
    // BCol,
    VSelect,
    PopupCategoryTiktok,
    SyncShopee,
    SyncLazada,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  mixins: [general],
  props: {
    cate: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      // fields: [
      //   // 'show_details',
      //   { key: 'avatar', label: 'Avatar', sortable: false },
      //   { key: 'name', label: 'Name', sortable: true },
      //   { key: 'sku', label: 'SKU', sortable: false },
      //   { key: 'price', label: 'Price', sortable: true },
      //   { key: 'stock', label: 'Stock', sortable: false },
      //   { key: 'sold', label: 'Sold', sortable: false },
      //   // { key: 'position', label: 'Position', sortable: true },
      //   // { key: 'created_at', label: 'Created at', sortable: true },
      //   { key: 'is_active', label: 'Status', sortable: false },
      //   // { key: 'lang', label: 'Language', sortable: false },
      //   { key: 'more', label: 'Tool', sortable: false },
      //   { key: 'sort', label: 'Reorder', sortable: false },
      // ],
      totalPage: 0,
      currentPageOriginal: 1,
      items: [],
      itemsTemp: [],
      itemsDeleted: [],
      model: {
        percent: null,
        is_active: true,
        list_product: [],
        is_min_total_price: 0,
        min_total_price: null,
        type: 1,
        discount_money: null,
      },
      fields: [
        { field: 'name', label: 'Product', sortable: true },
        { field: 'price', label: 'Price', sortable: false },
        // {
        //   field: 'quantity',
        //   label: 'Quantity',
        //   tdClass: 'text-center',
        //   sortable: false,
        // },
        { field: 'channel', label: 'Channel', sortable: false },
        { field: 'is_active', label: 'Status', sortable: false },
        { field: 'actions', label: 'Actions', sortable: false },
      ],
      pageLength: 10,
      searchTerm: '',
      filterSelected: 'all',
      // filterTabs: [
      //   {
      //     id: 'all',
      //     label: 'All',
      //   },
      //   {
      //     id: true,
      //     label: 'product_status_active',
      //   },
      //   {
      //     id: false,
      //     label: 'product_status_disable',
      //   },
      // ],
      productCategories: [
        {
          id: 1,
          name: 'Lương thực và các loại hạt',
        },
        {
          id: 2,
          name: 'Thủy hải sản',
        },
        {
          id: 3,
          name: 'Gia vị',
        },
        {
          id: 4,
          name: 'Thịt',
        },
        {
          id: 5,
          name: 'Rau củ quả',
        },
      ],
      productCategorySelected: null,
      salesChannels: [
        {
          id: 0,
          name: 'Website',
        },
        {
          id: 2,
          name: 'Lazada',
        },
        {
          id: 1,
          name: 'Shopee',
        },
        {
          id: 3,
          name: 'Tiktok',
        },
      ],
      salesChannelSelected: null,
      productSelected: [],
      currentLang: JSON.parse(localStorage.getItem('siteID')).language,
      modelSyncTiktok: {
        category_id: null,
        attribute_id: [],
      },
      warehouseTiktok: [],
      attributeTiktok: [],
      sycnning: true,
      countDone: 0,
      countError: 0,
      isActiveSubmit: true,
      tempSelectedProduct: [],
      modelFilter: {
        productCategorySelected: null,
        e_commerce: null,
        search: null,
      },
      activeFilter: false,
    }
  },
  computed: {
    filterItems() {
      if (this.filterSelected === 'all') {
        // eslint-disable-next-line no-return-assign, vue/no-side-effects-in-computed-properties
        return this.items = this.itemsTemp
      }
      if (this.filterSelected === 'delete') {
        // eslint-disable-next-line no-return-assign, vue/no-side-effects-in-computed-properties
        return this.items = this.itemsDeleted
      }
      // eslint-disable-next-line no-return-assign, vue/no-side-effects-in-computed-properties
      return this.items = this.itemsTemp.filter(item => item.is_active === this.filterSelected)
    },
    filterTabs() {
      let result = []
      if (this.salesChannelSelected === null || this.salesChannelSelected === 'website') {
        result = [
          {
            id: 'all',
            label: 'All',
          },
          {
            id: true,
            label: 'product_status_active',
          },
          {
            id: false,
            label: 'product_status_disable',
          },
          {
            id: 'delete',
            label: 'product_status_delete',
          },
        ]
      } else {
        result = [
          {
            id: 'all',
            label: 'All',
          },
          {
            id: 'synced',
            label: 'product_status_synced',
          },
          {
            id: 'not_sync',
            label: 'product_status_not_sync',
          },
        ]
      }
      return result
    },
  },
  watch: {
    // eslint-disable-next-line object-shorthand
    'modelSyncTiktok.category_id'() {
      this.getAttributeTiktok()
      this.isActiveSubmit = true
    },
    currentPageOriginal() {
      this.loadList()
    },
    pageLength() {
      this.loadList()
    },
    // eslint-disable-next-line object-shorthand
    '$route'() {
      this.loadList()
    },
    // eslint-disable-next-line object-shorthand
    'modelFilter.productCategorySelected'() {
      this.handleFilter()
    },
    // eslint-disable-next-line object-shorthand
    'modelFilter.search'() {
      if (this.modelFilter.search === null) this.handleFilter()
    },
    // eslint-disable-next-line object-shorthand
    'modelFilter.e_commerce'() {
      this.handleFilter()
    },
  },
  async mounted() {
    await this.loadList()
    this.loadListDelete()
    this.loadCategory()
  },
  methods: {
    async handlerSort(data) {
      const { item } = data
      const { method } = data
      try {
        const params = {
          site_id: JSON.parse(localStorage.getItem('siteID')).id,
          type: 1,
          ids: [],
        }
        if (method === 'up') {
          params.ids = [item.item.id, this.items[item.index - 1].id]
        }
        if (method === 'down') {
          params.ids = [item.item.id, this.items[item.index + 1].id]
        }
        if (method === 'change') {
          params.type = 2
          params.ids = [
            {
              id: item.item.id,
              position: Number(item.item.position),
            },
          ]
        }
        const res = await Request.post(
          this.$http,
          `${process.env.VUE_APP_API_URL}/product/change_position`,
          params,
        )
        if (res.status === 200) {
          if (res.data.status) {
            this.loadList()
          } else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Opps! Something wrong',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
                text: String(this.showError(res.data.error, ',')),
              },
            })
          }
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    filterReset() {
      this.modelFilter = {
        productCategorySelected: null,
        e_commerce: null,
        search: null,
      }
      this.$router.replace({})
    },
    handleFilter() {
      const params = {}
      if (this.modelFilter.productCategorySelected) params.category_id = this.modelFilter.productCategorySelected
      if (this.modelFilter.e_commerce !== null) params.e_commerce = this.modelFilter.e_commerce
      if (this.modelFilter.search !== null) params.search = this.modelFilter.search
      this.$router.replace({
        query: params,
      }).catch(() => {})
      this.loadList()
      this.activeFilter = true
    },
    async loadCategory() {
      const siteId = JSON.parse(localStorage.getItem('siteID')).id
      const params = `site_id=${siteId}`
      try {
        const result = await Request.get(
          this.$http,
          `${process.env.VUE_APP_API_URL}/category/tree?${params}&sort[]=position,asc`,
        )
        if (result.status === 200) {
          this.productCategories = []
          const res = result.data
          const list = await this.getDataTranslation(res.data, true)
          // eslint-disable-next-line array-callback-return
          list.map(val => {
            const item = {
              id: val.id,
              id_number: val.id_number,
              name: val.name,
            }
            this.productCategories.push(item)
          })
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
    },
    async loadList() {
      const siteId = JSON.parse(localStorage.getItem('siteID')).id
      let params = `site_id=${siteId}`
      if (this.$route.query.category_id) params += `&category_id=${this.$route.query.category_id}`
      if (this.$route.query.e_commerce) params += `&e_commerce=${this.$route.query.e_commerce}`
      if (this.$route.query.search) params += `&name=${this.$route.query.search}`
      // if (this.$route.params && this.$route.params.id) {
      //   params = `${params}&category_id=${this.$route.params.id.split('-')[0]}`
      // }
      try {
        const result = await Request.get(
          this.$http,
          `${process.env.VUE_APP_API_URL}/products?${params}&sort[]=created_at,desc&page=${this.currentPageOriginal}&limit=${this.pageLength}`,
        )
        if (result.status === 200) {
          const res = result.data.data
          this.totalPage = res.total
          this.items = []
          this.itemsTemp = []
          const list = await this.getDataTranslation(res.items, true)
          // eslint-disable-next-line array-callback-return
          list.map(val => {
            const item = {
              id: val.id,
              id_product: val.id_product,
              name: val.name,
              avatar: val.avatar,
              price: this.getMinMaxPrice(val.warehouse),
              // price_min: Math.min(this.getMinMaxPrice(val.warehouse)),
              // price_max: Math.max(...val.warehouse.map(x => x.price)),
              quantity: 0,
              is_active: val.is_active,
              is_delete: 0,
              shopee_id: val.shopee_id,
              lazada_id: val.lazada_id,
              tiktok_id: val.tiktok_id,
              totalAttribute: 0,
            }
            // eslint-disable-next-line no-unused-expressions, array-callback-return
            val.warehouse ? val.warehouse.map(x => {
              item.quantity += x.quantity
            }) : false

            // eslint-disable-next-line no-unused-expressions
            val.warehouse ? item.totalAttribute = val.warehouse.length : false
            this.itemsTemp.push(item)
            this.itemsTemp.sort((a, b) => b.id_product - a.id_product)
          })
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
    },
    async loadListDelete() {
      const siteId = JSON.parse(localStorage.getItem('siteID')).id_number
      let params = `site_id=${siteId}`
      if (this.$route.params && this.$route.params.id) {
        params = `${params}&category_id=${this.$route.params.id.split('-')[0]}`
      }
      try {
        const result = await Request.get(
          this.$http,
          `${process.env.VUE_APP_API_URL}/v2/products_delete?${params}&sort[]=created_at,desc`,
        )
        if (result.status === 200) {
          this.itemsDeleted = []
          const res = result.data.data
          const list = await this.getDataTranslation(res.items, true)
          // eslint-disable-next-line array-callback-return
          list.map(val => {
            const item = {
              id: val.id,
              id_product: val.id_product,
              name: val.name,
              avatar: val.avatar,
              price: this.getMinMaxPrice(val.warehouse),
              quantity: 0,
              shopee_id: val.shopee_id,
              lazada_id: val.lazada_id,
              tiktok_id: val.tiktok_id,
              totalAttribute: 0,
              is_active: null,
              is_delete: 1,
            }
            // eslint-disable-next-line no-unused-expressions, array-callback-return
            val.warehouse ? val.warehouse.map(x => {
              item.quantity += x.quantity
            }) : false

            // eslint-disable-next-line no-unused-expressions
            val.warehouse ? item.totalAttribute = val.warehouse.length : false
            this.itemsDeleted.push(item)
            this.itemsDeleted.sort((a, b) => b.id_product - a.id_product)
          })
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
    },
    getMinMaxPrice(warehouse) {
      if (warehouse.length === 0) return NaN
      let t = 0
      let min = Number.POSITIVE_INFINITY
      let max = Number.NEGATIVE_INFINITY
      // eslint-disable-next-line array-callback-return
      warehouse.map((x, index) => {
        t = Number(warehouse[index].price)
        if (t < min) min = t
        if (t > max) max = t
      })
      if (min === max) return this.unitFormatOriginal(min)
      return `${this.unitFormatOriginal(min)} - ${this.unitFormatOriginal(max)}`
    },
    async handlerDelete(id) {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
          try {
            const siteId = JSON.parse(localStorage.getItem('siteID')).id
            const res = await Request.delete(
              this.$http,
              `${process.env.VUE_APP_API_URL}/product/${id}?site_id=${siteId}`,
            )
            if (res.status === 200) {
              if (res.data.status) {
                this.$swal({
                  icon: 'success',
                  title: 'Deleted!',
                  text: 'Your Product has been deleted.',
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                })
                this.loadList()
              } else {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Opps! Something wrong',
                    icon: 'AlertOctagonIcon',
                    variant: 'danger',
                    text: String(this.showError(res.data.error, ',')),
                  },
                })
              }
            }
          } catch (error) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Opps! Something wrong',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
                text: String(error),
              },
            })
          }
        }
      })
    },
    productCounter(key) {
      let result = 0

      if (key === 'all') {
        result = this.items.length
      } else if (key === true) {
        result = this.items.filter(item => item.is_active === true).length
      } else if (key === false) {
        result = this.items.filter(item => item.is_active === false).length
      } else if (key === 'delete') {
        result = this.items.filter(item => item.is_delete === 1).length
      }
      return result
    },
    selectionChanged(data) {
      this.productSelected = []
      this.tempSelectedProduct = data.selectedRows
      // eslint-disable-next-line array-callback-return
      data.selectedRows.map(item => {
        this.productSelected.push({
          ...item,
          loading: 0,
          error_text: null,
        })
      })
    },
    showPopupTiktok() {
      this.productSelected = []
      // eslint-disable-next-line array-callback-return
      this.tempSelectedProduct.map(item => {
        this.productSelected.push({
          ...item,
          loading: 0,
          error_text: null,
        })
      })
      this.$bvModal.show('modal-popup-sync-product')
      this.getWarehouseTiktok()
    },
    showPopupShopee() {
      this.productSelected = []
      // eslint-disable-next-line array-callback-return
      this.tempSelectedProduct.map(item => {
        this.productSelected.push({
          ...item,
          loading: 0,
          error_text: null,
        })
      })
      this.$bvModal.show('modal-popup-sync-product-shopee')
    },
    showPopupLazada() {
      this.productSelected = []
      // eslint-disable-next-line array-callback-return
      this.tempSelectedProduct.map(item => {
        this.productSelected.push({
          ...item,
          loading: 0,
          error_text: null,
        })
      })
      this.$bvModal.show('modal-popup-sync-product-lazada')
    },
    async getWarehouseTiktok() {
      const siteId = JSON.parse(localStorage.getItem('siteID')).id
      try {
        const res = await Request.get(
          this.$http,
          `${process.env.VUE_APP_API_URL}/tiktok/v1/get_warehouse_list?site_id=${siteId}`,
        )
        if (res.data.status) {
          this.warehouseTiktok = []
          // eslint-disable-next-line array-callback-return
          res.data.data.warehouse_list.map(item => {
            if (item.warehouse_type === 1) {
              this.warehouseTiktok.push(item)
            }
          })
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
    },
    async getAttributeTiktok() {
      const siteId = JSON.parse(localStorage.getItem('siteID')).id
      try {
        const res = await Request.get(
          this.$http,
          `${process.env.VUE_APP_API_URL}/tiktok/v1/get_attributes?site_id=${siteId}&category_id=${this.modelSyncTiktok.category_id}`,
        )
        if (res.data.status) {
          this.attributeTiktok = res.data.data.attributes
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
    },
    closeModalTiktok() {
      this.$bvModal.hide('modal-popup-sync-product')
    },
    async handleSyncToTiktok() {
      // this.isActiveSubmit = false
      this.sycnning = false
      this.countDone = 0
      this.countError = 0
      this.productSelected.map(async (item, index) => {
        try {
          this.productSelected[index].loading = 1
          const siteId = JSON.parse(localStorage.getItem('siteID')).id
          const params = {
            site_id: siteId,
            product_id: item.id_product,
            category_id: this.modelSyncTiktok.category_id,
            attribute_ids: JSON.stringify(this.modelSyncTiktok.attribute_id),
            warehouse_id: this.modelSyncTiktok.warehouse_id,
            is_cod_open: 'false',
          }
          const res = await Request.post(
            this.$http,
            `${process.env.VUE_APP_API_URL}/tiktok/v1/create_product`, params,
          )
          if (res.status === 200) {
            if (res.data.status) {
              this.productSelected[index].loading = 2
              this.countDone += 1
            } else {
              this.productSelected[index].loading = 3
              this.productSelected[index].error_text = String(this.showError(res.data.error, ','))
              this.countError += 1
            }
          }
        } catch (error) {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Opps! Something wrong',
              icon: 'AlertOctagonIcon',
              variant: 'danger',
              text: String(error),
            },
          })
          this.isShow = false
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.bg-gray {
  background: #f6f6f6;
  // padding: 12px 0;
}
.custom-good-table .vgt-table {
  font-size: 14px;

  thead th {
    vertical-align: middle;
    text-align: center;
    padding-right: 0.75em;
    white-space: nowrap;
  }
  td {
    vertical-align: middle;
  }

}

.custom-good-table {
  .custom-class {
    display: flex;
    align-items: center;

    .vgt-pull-right {
      margin-left: 16px;
    }
  }
}

.v-select-custom {
  .vs {
    &__selected-options {
      max-width: calc(100% - 24px);
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      flex-wrap: nowrap;
    }
    &__search {
      &::placeholder {
        color: var(--gray)
      }
    }
  }
}
</style>

<style lang="sass">
.product-list
  .bd-b-1
    border-bottom: 1px solid #e5e5e5
  .filter
    &-tab
      display: flex
      overflow: hidden
      flex: none
      // border-bottom: 1px solid #e5e5e5

      &__item
        min-width: 64px
        padding: 0 16px
        height: 56px
        display: flex
        align-items: center
        font-weight: 500
        font-size: 14px
        border-bottom: 3px solid transparent
        cursor: pointer
        transition: all .2s

        &.active,
        &:hover
          color: var(--primary)

        &.active
          border-bottom-color: var(--primary)

  .filter-advance
    display: grid
    gap: 16px

    &__search
      display: grid
      gap: 8px
      grid-template-columns: auto 160px 160px
      width: 100%
      // grid-template-columns: 1fr repeat(3, 200px)

      .actions
        > div
          display: grid
          align-items: center
          grid-template-columns: repeat(2, 1fr)
          gap: 8px

        .btn
          padding-left: 1rem
          padding-right: 1rem
</style>
