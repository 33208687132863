import { render, staticRenderFns } from "./PopupTiktok.vue?vue&type=template&id=38a5c38b&scoped=true&"
import script from "./PopupTiktok.vue?vue&type=script&lang=js&"
export * from "./PopupTiktok.vue?vue&type=script&lang=js&"
import style0 from "./PopupTiktok.vue?vue&type=style&index=0&id=38a5c38b&prod&lang=sass&"
import style1 from "./PopupTiktok.vue?vue&type=style&index=1&id=38a5c38b&prod&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "38a5c38b",
  null
  
)

export default component.exports