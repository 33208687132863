<template>
  <b-modal
    id="modal-popup-category-lazada"
    ok-title="Save"
    centered
    size="xl"
    title="Chọn danh mục của Lazada"
    hide-footer
  >
    <div class="container-ctrl">
      <div>
        <b-row>
          <b-col md="12">
            <div class="box-cate">
              <!-- <div class="input-search">
                <b-input-group class="input-group-merge">
                  <b-input-group-prepend is-text>
                    <feather-icon icon="SearchIcon" />
                  </b-input-group-prepend>
                  <b-form-input placeholder="Search" />
                </b-input-group>
              </div> -->
              <div class="cate-list">
                <b-row>
                  <b-col md="3">
                    <ul class="list-item">
                      <li
                        v-for="(item, index) in parents"
                        :key="index"
                        class="item"
                        :class="selected1.category_id == item.category_id ? `active` : ''"
                        @click.stop="setSelected1(item)"
                      >
                        <span>{{ item.name }}</span>
                      </li>
                    </ul>
                  </b-col>
                  <b-col md="3">
                    <ul class="list-item">
                      <!-- eslint-disable-next-line vue/no-use-v-if-with-v-for -->
                      <li
                        v-for="(item2, index) in listCateChild1"
                        :key="index"
                        class="item"
                        :class="{
                          active: selected2.category_id === item2.category_id,
                        }"
                        @click.stop="setSelected2(item2)"
                      >
                        <span>
                          {{ item2.name }}
                        </span>
                      </li>
                    </ul>
                  </b-col>
                  <b-col md="3">
                    <ul class="list-item">
                      <li
                        v-for="(item3, index) in listCateChild2"
                        :key="index"
                        class="item d-none-after"
                        :class="{
                          active: selected3.category_id === item3.category_id,
                        }"
                        @click.stop="setSelected3(item3)"
                      >
                        <span>{{ item3.name }}</span>
                      </li>
                    </ul>
                  </b-col>
                  <b-col md="3">
                    <ul class="list-item">
                      <li
                        v-for="(item4, index) in listCateChild3"
                        :key="index"
                        class="item d-none-after"
                        :class="{
                          active: selected4.category_id === item4.category_id,
                        }"
                        @click.stop="setSelected4(item4)"
                      >
                        <span>{{ item4.name }}</span>
                      </li>
                    </ul>
                  </b-col>
                </b-row>
              </div>
            </div>
            <div class="current">
              <p>
                The currently selected :
                <b>{{ selected1.name }}</b>
                <b
                  v-if="
                    selected2 &&
                      selected2 !== '' &&
                      selected2.name !== undefined
                  "
                >
                  > {{ selected2.name }}</b>
                <b
                  v-if="
                    selected3 &&
                      selected3 !== '' &&
                      selected3.name !== undefined
                  "
                >
                  > {{ selected3.name }}</b>
                <b
                  v-if="
                    selected4 &&
                      selected4 !== '' &&
                      selected4.name !== undefined
                  "
                >
                  > {{ selected4.name }}</b>
              </p>
            </div>
          </b-col>
        </b-row>
      </div>
      <hr>
      <div class="text-right mt-2">
        <b-button
          variant="outline-primary"
          type="button"
          size="sm"
        >
          {{ $t('Cancel') }}
        </b-button>
        <b-button
          variant="primary"
          type="button"
          class="ml-2"
          size="sm"
          @click="handleSetCategory"
        >
          {{ $t('Submit') }}
        </b-button>
      </div>
    </div>
  </b-modal>
</template>

<script>
/* eslint-disable array-callback-return */
import {
  BButton,
  BRow,
  BCol,
  // BInputGroupPrepend,
  // BInputGroup,
  // BFormInput,
} from 'bootstrap-vue'
import Request from '@/plugins/service/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BButton,
    BRow,
    BCol,
    // BInputGroupPrepend,
    // BInputGroup,
    // BFormInput,
  },
  props: {
    value: {
      type: Object,
      default() {
        return {}
      },
    },
    id: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      categoryList: [],
      parents: [],
      listCateChild1: [],
      listCateChild2: [],
      listCateChild3: [],
      selected1: '',
      selected2: '',
      selected3: '',
      selected4: '',
    }
  },
  created() {
    this.loadCategory()
  },
  methods: {
    async loadCategory() {
      const siteId = JSON.parse(localStorage.getItem('siteID')).id
      try {
        const result = await Request.get(
          this.$http,
          `${process.env.VUE_APP_API_URL}/lazada/get_categories?site_id=${siteId}&language_code=vi_VN`,
        )
        if (result.data.status) {
          const res = result.data.data
          // res.map(val => {
          //   if (Number(val.parent_id) === 0) {
          //     this.parents.push({
          //       ...val,
          //     })
          //   }
          // })
          this.categoryList = res
          this.parents = res
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
    },
    setSelected1(val) {
      this.selected1 = val
      this.selected2 = ''
      this.selected3 = ''
      this.selected4 = ''
      this.listCateChild1 = []
      // eslint-disable-next-line array-callback-return
      // this.categoryList.map(x => {
      //   if (Number(x.parent_id) === Number(val.category_id)) {
      //     // eslint-disable-next-line array-callback-return
      //     this.listCateChild1.push(x)
      //   }
      // })
      this.listCateChild1 = val.children
      // this.categoryList.map(x => {
      //   if (x.category_id === val.category_id) {
      //     // eslint-disable-next-line array-callback-return
      //   }
      // })
    },
    setSelected2(val) {
      this.selected2 = val
      this.selected3 = ''
      this.selected4 = ''
      this.listCateChild2 = []
      this.listCateChild2 = val.children
      // eslint-disable-next-line array-callback-return
      // this.categoryList.map(x => {
      //   if (Number(x.parent_id) === Number(val.category_id)) {
      //     // eslint-disable-next-line array-callback-return
      //     this.listCateChild2.push(x)
      //   }
      // })
    },
    setSelected3(val) {
      this.selected3 = val
      this.selected4 = ''
      this.listCateChild3 = []
      this.listCateChild3 = val.children
      // eslint-disable-next-line array-callback-return
      // this.categoryList.map(x => {
      //   if (Number(x.parent_id) === Number(val.category_id)) {
      //     // eslint-disable-next-line array-callback-return
      //     this.listCateChild2.push(x)
      //   }
      // })
    },
    setSelected4(val) {
      this.selected4 = val
    },
    handleSetCategory() {
      this.value.category_id = null
      const selected = {
        level_1: this.selected1,
        level_2: this.selected2,
        level_3: this.selected3,
        level_4: this.selected4,
      }
      if (selected.level_1.name === undefined) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: 'Please select the category',
          },
        })
        return true
      }
      if (selected.level_2.name === undefined) {
        this.value.category_id = `${selected.level_1.category_id}`
        this.value.category_name = `${selected.level_1.name}`
        this.$bvModal.hide('modal-popup-category-lazada')
        return true
      }
      if (selected.level_3.name === undefined) {
        this.value.category_id = `${selected.level_2.category_id}`
        this.value.category_name = `${selected.level_1.name} > ${selected.level_2.name}`
        this.$bvModal.hide('modal-popup-category-lazada')
        return true
      }
      if (selected.level_4.name === undefined) {
        this.value.category_id = `${selected.level_3.category_id}`
        this.value.category_name = `${selected.level_1.name} > ${selected.level_2.name} > ${selected.level_3.name}`
        this.$bvModal.hide('modal-popup-category-lazada')
        return true
      }
      this.value.category_id = `${selected.level_4.category_id}`
      this.value.category_name = `${selected.level_1.name} > ${selected.level_2.name} > ${selected.level_3.name} > ${selected.level_4.name}`
      this.$bvModal.hide('modal-popup-category-lazada')
      return true
    },
  },
}
</script>

<style lang="sass">
#category, #lazada, #tiktok, #shopee
  .modal-xl
    transform: none
    width: 70% !important
    max-width: initial
    max-width: 70% !important
    margin-left: auto !important
    margin-right: auto !important
</style>
<style lang="sass" scoped>
.container-ctrl
  padding-left: 1rem
  padding-right: 1rem

  .box-cate
    background-color: #F7F7F7
    padding: 1rem

    .cate-list
      background-color: #fff
      margin-top: 1rem
      padding: 1rem
      min-height: 25rem

      .list-item
        padding-left: 0
        overflow-y: scroll
        height: 25rem
        border-right: 1px solid #b0b0b0

        &::-webkit-scrollbar-track
          border-radius: 10px
          background-color: #fff

        &::-webkit-scrollbar
          width: 6px
          background-color: #fff

        &::-webkit-scrollbar-thumb
          border-radius: 99px
          background-color: #808080

        .active
          font-weight: 500
          background-color: #fff
        .item
          display: flex
          justify-content: space-between
          list-style: none
          padding: 10px 15px
          // text-decoration: none
          color: #000
          cursor: pointer
          &.d-none-after
            &::after
              display: none
              opacity: 0

          & > span
            white-space: nowrap
            overflow: hidden
            text-overflow: ellipsis

          &:hover
            font-weight: 500
            background-color: #f5f5f5

          &::after

            content: ""
            display: inline-block
            vertical-align: middle
            width: 6px
            height: 13px
            margin-left: 1rem
            transition: transform 350ms
            transform: rotate(0)
            border-width: .5rem 0 .5rem .5rem
            border-color: transparent transparent transparent #ccc
            border-style: solid
  .current
    margin-top: 1rem
</style>
