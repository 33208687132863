<template>
  <b-modal
    id="modal-popup-sync-product-lazada"
    ok-title="Save"
    scrollable
    size="lg"
    title="Đồng bộ sản phẩm lên Lazada"
    no-close-on-backdrop
    hide-footer
  >
    <div class="container-ctrl">
      <div>
        <ul v-if="sycnning">
          <li>
            Người dùng cần chọn danh mục theo danh mục Lazada Shop
          </li>
        </ul>
        <span v-else>Đồng bộ thành công <strong class="text-success">{{ countDone }}/{{ value.length }} sản phẩm</strong>, <span class="text-danger">{{ countError }} đồng bộ lỗi</span></span>
        <!-- <p>Please choose the right category for your product.</p> -->
        <hr>
        <!-- <b-form-group :label="`*`+$t('Logistic')+`:`" label-cols-md="12" class="align-items-center">
          <v-select
            v-model="modelSync.logistic_id"
            label="logistic_name"
            class="v-select-custom"
            :options="logistics"
            :clearable="false"
            :placeholder="$t('Select warehouse')"
            :reduce="(option) => option.logistic_id"
          />
        </b-form-group> -->
        <b-form-group
          :label="`*`+$t('Category')+`:`"
          label-cols-md="12"
          class="align-items-center"
        >
          <b-input-group
            v-b-modal.modal-popup-category-lazada
            class="input-group-merge"
          >
            <b-input-group-prepend is-text>
              <feather-icon icon="Edit2Icon" />
            </b-input-group-prepend>
            <b-form-input
              v-model="modelSync.category_name"
              readonly
              :placeholder="$t('Select product category')"
            />
          </b-input-group>
          <!-- <small v-if="categoryIdErrorText !== null" class="text-danger">{{
            categoryIdErrorText
          }}</small> -->
          <popup-category-lazada v-model="modelSync" />
        </b-form-group>
        <!-- <b-form-group v-if="attributeTiktok && attributeTiktok.length > 0" :label="`*`+$t('Attribute')+`:`" label-cols-md="12" class="align-items-center">
          <v-select
            v-model="modelSync.attribute_id"
            label="name"
            class="v-select-custom"
            multiple
            :options="attributeTiktok"
            :clearable="false"
            :placeholder="$t('Select attribute')"
            :reduce="(option) => option.id"
          />
        </b-form-group> -->
      </div>
      <div>
        <div class="d-flex justify-content-between p-1 bg-gray">
          <div>Sản Phẩm</div>
          <div>Trạng thái</div>
        </div>
        <div
          v-for="(item, index) in value"
          :key="index"
          class="d-flex justify-content-between p-1"
        >
          <div class="d-flex align-items-center">
            <b-avatar
              square
              variant="light-dark"
              :src="item.avatar"
            />
            <span class="pl-1">{{ item.name }}</span>
          </div>
          <div class="pl-2">
            <feather-icon
              v-if="item.loading == 0"
              icon="MinusIcon"
              size="24"
              class="text-primary"
            />
            <b-spinner
              v-else-if="item.loading == 1"
              label="Loading..."
            />
            <feather-icon
              v-else-if="item.loading == 2"
              icon="CheckIcon"
              size="24"
              class="text-success"
            />
            <feather-icon
              v-else
              v-b-tooltip.hover.top="item.error_text"
              icon="AlertCircleIcon"
              size="24"
              class="text-warning"
            />
          </div>
        </div>
      </div>
      <hr>
      <div class="text-right mt-2">
        <b-button
          variant="outline-primary"
          type="button"
          size="sm"
          @click="closeModal"
        >
          {{ $t('Cancel') }}
        </b-button>
        <b-button
          variant="primary"
          type="button"
          class="ml-2"
          size="sm"
          :disabled="isActiveSubmit ? false : true"
          @click="handleSyncToLazada"
        >
          <feather-icon
            icon="RefreshCwIcon"
            class="mr-50"
          />
          {{ $t('Sync product') }}
        </b-button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import {
  BButton,
  BAvatar,
  BFormInput,
  BFormGroup,
  BInputGroup,
  BInputGroupPrepend,
  BSpinner,
  VBTooltip,
  // BProgress,
  // BCollapse,
  // BRow,
  // BCol,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import Request from '@/plugins/service/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// import VSelect from 'vue-select'
import PopupCategoryLazada from '../componentCategoryChannels/PopupCategoryLazada.vue'

export default {
  components: {
    // PopupAddProduct,
    BButton,
    BAvatar,
    BFormInput,
    BFormGroup,
    BInputGroup,
    BInputGroupPrepend,
    BSpinner,
    PopupCategoryLazada,
    // BProgress,
    // BCollapse,
    // BRow,
    // BCol,
    // VSelect,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {
    value: {
      type: Array,
      default() {
        return []
      },
    },
  },
  data() {
    return {
      logistics: [],
      isActiveSubmit: true,
      sycnning: true,
      modelSync: {
        category_id: null,
        category_name: '',
        logistic_id: null,
      },
      countError: 0,
      countDone: 0,
    }
  },
  watch: {
    // eslint-disable-next-line object-shorthand
    'modelSync.category_id'() {
      this.isActiveSubmit = true
    },
  },
  created() {
    this.getLogistic()
  },
  methods: {
    closeModal() {
      this.$bvModal.hide('modal-popup-sync-product-lazada')
    },
    async handleSyncToLazada() {
      this.isActiveSubmit = false
      this.sycnning = false
      this.countDone = 0
      this.countError = 0
      this.value.map(async (item, index) => {
        try {
          const siteId = JSON.parse(localStorage.getItem('siteID')).id
          this.value[index].loading = 1
          // const siteId = JSON.parse(localStorage.getItem('siteID')).id
          const params = {
            site_id: siteId,
            product_id: item.id_product,
            category_id: this.modelSync.category_id,
          }
          const res = await Request.post(
            this.$http,
            `${process.env.VUE_APP_API_URL}/lazada/v2/sync_to_lazada`, params,
          )
          if (res.status === 200) {
            if (res.data.status) {
              this.value[index].loading = 2
              this.countDone += 1
            } else {
              this.value[index].loading = 3
              // eslint-disable-next-line prefer-destructuring
              this.value[index].error_text = (res.data.error.detail[0])
              this.countError += 1
            }
          }
        } catch (error) {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Opps! Something wrong',
              icon: 'AlertOctagonIcon',
              variant: 'danger',
              text: String(error),
            },
          })
          this.isShow = false
        }
      })
    },
    async getLogistic() {
      const siteId = JSON.parse(localStorage.getItem('siteID')).id
      try {
        const res = await Request.get(
          this.$http,
          `${process.env.VUE_APP_API_URL}/shopee/get_logistics?site_id=${siteId}`,
        )
        if (res.data.status) {
          this.logistics = []
          // eslint-disable-next-line array-callback-return
          res.data.data.map(item => {
            if (item.mask_channel_id === 0) {
              this.logistics.push(item)
            }
          })
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
    },
  },
}
</script>

<style>

</style>
